import { render, staticRenderFns } from "./AboutUs.html?vue&type=template&id=232de7fe&scoped=true&"
import script from "./AboutUs.js?vue&type=script&lang=js&"
export * from "./AboutUs.js?vue&type=script&lang=js&"
import style0 from "./AboutUs.scss?vue&type=style&index=0&id=232de7fe&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "232de7fe",
  null
  
)

export default component.exports